<template>
  <div class="pt-4">
    <router-link
      to="/numbers"
      class="back-link px-3 px-md-0"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Numbers
    </router-link>

    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 px-4 px-md-0">Buy a number</h1>

    <v-card class="pa-5 mb-5 overflow-hidden">
      <v-form v-model="valid">
        <v-row>
          <v-col
            cols="12"
            md="auto"
            class="pb-0"
          >
            <v-radio-group
              v-model="radioGroup"
              class="mt-0 pt-0 grey-radio--button"
            >
              <v-radio
                v-for="item in radioItem"
                :key="item"
                :label="`${item}`"
                :value="item"
                class="text-pre"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            md="auto"
            class="pb-0"
          >
            <!--            <v-text-field-->
            <!--              v-if="radioGroup === 'Local numbers'"-->
            <!--              solo-->
            <!--              outlined-->
            <!--              label="Search ZIP code or area code"-->
            <!--              type="search"-->
            <!--              v-model="search"-->
            <!--              dense-->
            <!--              hide-details-->
            <!--            >-->
            <!--              <template v-slot:prepend-inner>-->
            <!--                <v-icon color="#919EAB" size="20px" class="mt-1">search</v-icon>-->
            <!--              </template>-->
            <!--            </v-text-field>-->

            <v-autocomplete
              v-if="radioGroup === 'State'"
              solo
              outlined
              v-model="localNumber"
              :items="form.numberList"
              label="Search ZIP code or area code"
              hide-details
              menu-props="offsetY"
              append-icon=""
              dense
              class="pa-0 fz-14"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#919EAB" size="20px" class="mt-1">search</v-icon>
              </template>
            </v-autocomplete>

<!--            <v-text-field-->
<!--              v-else-->
<!--              solo-->
<!--              outlined-->
<!--              label="Search country"-->
<!--              type="search"-->
<!--              v-model="searchCountry"-->
<!--              dense-->
<!--              hide-details-->
<!--            >-->
<!--              <template v-slot:prepend-inner>-->
<!--                <v-icon color="#919EAB" size="20px" class="mt-1">search</v-icon>-->
<!--              </template>-->
<!--            </v-text-field>-->

            <v-autocomplete
              v-else
              solo
              outlined
              v-model="TFNumber"
              :items="form.numberList"
              label="Search country"
              hide-details
              menu-props="offsetY"
              append-icon=""
              dense
              class="pa-0 fz-14"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#919EAB" size="20px" class="mt-1">search</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="radioGroup === 'Number Type'">
          <v-col
            cols="12"
            md="12"
            class="px-0"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0 text-uppercase fz-12 font-weight-bold"
          >
            Long Code
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="d-flex flex-wrap pb-0"
          >
            <v-checkbox
              v-for="item in standardNumbers"
              :key="item"
              v-model="form.numberList"
              :label="item"
              :value="item"
              class="mt-0 mb-4 mr-4 violet-checkbox"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0 text-uppercase fz-12 font-weight-bold"
          >
            toll free numbers
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="d-flex flex-wrap pb-0"
          >
            <v-checkbox
              v-for="item in tollFreeNumbers"
              :key="item"
              v-model="form.numberList"
              :label="item"
              :value="item"
              class="mt-0 mb-4 mr-4 violet-checkbox"
            ></v-checkbox>
          </v-col>
        </v-row>

<!--        <v-row v-if="radioGroup === 'Country'">-->
<!--          <v-col-->
<!--            cols="12"-->
<!--            md="12"-->
<!--            class="px-0"-->
<!--          >-->
<!--            <v-divider class="hl-724"></v-divider>-->
<!--          </v-col>-->

<!--          <v-col-->
<!--            cols="12"-->
<!--            md="12"-->
<!--            class="py-0 text-uppercase fz-12 font-weight-bold"-->
<!--          >-->
<!--            Long Code-->
<!--          </v-col>-->

<!--          <v-col-->
<!--            cols="12"-->
<!--            md="12"-->
<!--            class="d-flex flex-wrap pb-0"-->
<!--          >-->
<!--            <v-checkbox-->
<!--              v-for="item in internationalNumbers"-->
<!--              :key="item"-->
<!--              v-model="form.numberList"-->
<!--              :label="item"-->
<!--              :value="item"-->
<!--              class="mt-0 mb-4 mr-4 violet-checkbox"-->
<!--            ></v-checkbox>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-form>
    </v-card>
    <header-buttons ok="Buy number" @ok="save" :changed="changed"/>
  </div>
</template>

<script>
import HeaderButtons from '@/components/HeaderButtons'

export default {
  name: 'ByANumber',
  components: { HeaderButtons },
  data: () => ({
    radioGroup: 'Country',
    radioItem: ['Country', 'Number Type', 'State'],
    standardNumbers: ['469-697-3338', '985-563-0628', '514-123-9513', '413-739-4255', '075-198-6872', '139-432-3628', '205-318-5126'],
    tollFreeNumbers: ['1800-697-3378', '1800-697-4823', '1800-289-1763', '1800-697-3338', '1800-873-1988', '1800-784-1311'],
    internationalNumbers: ['1800-697-3339', '1800-697-4824', '1800-289-1764', '1801-697-3338', '1800-373-1988', '1800-484-1311'],
    localNumber: '',
    TFNumber: '',
    search: '',
    searchCountry: '',
    form: {
      numberList: []
    },
    valid: false,
    changed: false
  }),
  watch: {
    form: {
      deep: true,
      handler () {
        this.changed = true
      }
    }
  },
  created () {
    this.getNumberList()
  },
  methods: {
    async getNumberList () {
      await this.$store
        .dispatch('numberStateLIst')
        .then((res) => {
          console.log('ress', res)
          this.form.numberList = res.data
        }).catch(err => {
          console.log('err', err)
        })
    },
    save () {
      console.log('number bought')
      this.$router.back()
    }
  }
}
</script>
